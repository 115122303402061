import React from "react";
import Layout from "../../components/Layout";
import Navbar from "../../components/Navbar";
import PageHeader from "../../components/PageHeader";
import Footer from "../../components/Footer";
import TeachersDetails from "../../components/TeachersDetails";
import anagha from "../../assets/images/profile/package.jpeg";
const data = {
  name: "Nikita Kashyap",
  description:
    "Nikita is an accomplished Early Years Educator and a certified Jolly Phonics Teacher, known for her passion and proficiency in working with children. Her expertise in English language development has been immensely beneficial to young learners. She conducts Jolly Phonics & Grammar classes not only for children but also extends her knowledge to teachers and parents. Moreover, Nikita plays a pivotal role as the facilitator for government school projects managed by Kids World Education Consultant, where her professional storytelling skills and educational strategies contribute significantly to the development and engagement of young minds.",
};

const TeachersDetailsPage = () => {
  return (
    <Layout pageTitle="KWEC | Teachers Details">
      <Navbar />
      <PageHeader title="Our Team" />
      <section className="team-details">
        <div className="container">
          <div className="row justify-content-between">
            <div className="col-lg-4">
              <div className="team-one__single">
                <div className="team-one__image">
                  <img src={anagha} alt="" />
                </div>
                <div className="team-one__content">
                  <h2 className="team-one__name">
                    {data.name}
                  </h2>
                  <p className="team-one__designation">Early Years Educator & Jolly Phonics Teacher</p>
                </div>
              </div>
            </div>
            <div className="col-lg-8">
              <div className="team-details__content">
                <h2 className="team-details__title">Read my story</h2>
                <p className="team-details__text">{data.description}</p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </Layout>
  );
};

export default TeachersDetailsPage;
